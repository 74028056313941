<template>
  <div class="pa-3">
    <div class="d-flex justify-center">
      <div class="col-sm-12 col-md-5 col-lg-3 d-flex flex-column">
        <h2 v-text="internalName"></h2>
      </div>
    </div>
    <div class="d-flex flex-column align-center">
      <div class="col-sm-12 col-md-5 col-lg-3 d-flex flex-column text-center">
        <h4 class="secondary py-1 rounded">Criterios de búsqueda</h4>
      </div>
      <div class="col-sm-12 col-md-5 col-lg-3">
        <v-tabs
          background-color="bg-transparent"
          v-model="tab"
          align-with-title
          height="80"
          class="d-flex justify-center"
          show-arrows
        >
          <v-tab class="rounded-lg elevation-3 mx-0 mr-2">
            <div class="d-flex flex-column" id="item-tab-1">
              <span v-text="'Empresa'" class="text-center"></span>
            </div>
          </v-tab>
          <v-tab class="rounded-lg elevation-3 mx-0 mr-2">
            <div class="d-flex flex-column" id="item-tab2">
              <span v-text="'Tipo'" class="text-center"></span>
              <span v-text="'de'" class="text-center"></span>
              <span v-text="'empresa'" class="text-center"></span>
            </div>
          </v-tab>
          <v-tab class="rounded-lg elevation-3 mx-0 mr-2">
            <div class="d-flex flex-column" id="item-tab-4">
              <span v-text="'Países'" class="text-center"></span>
            </div>
          </v-tab>
          <v-tab class="rounded-lg elevation-3 mx-0">
            <div class="d-flex flex-column" id="item-tab-5">
              <span v-text="'Pabellones'" class="text-center"></span>
            </div>
          </v-tab>
        </v-tabs>
      </div>
      <div class="col-sm-12 col-md-5 col-lg-3">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <div class="col-12 pa-0">
              <v-text-field
                v-model="params.filter"
                label="Nombre empresa"
                v-on:keyup.enter="search"
                outlined
                dense
                single-line
                hide-details
              />
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="col-12 pa-0">
              <v-select
                v-model="params.companyType"
                :items="companyTypes"
                item-text="name"
                item-value="id"
                outlined
                @change="search"
                dense
                hide-details
                label="Tipo de empresa"
              />
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="col-12 pa-0">
              <v-select
                v-model="params.country"
                :items="countries"
                item-text="name"
                item-value="id"
                outlined
                hide-details
                @change="search"
                dense
                label="País"
              />
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="col-12 pa-0">
              <v-select
                v-model="params.place"
                :items="places"
                item-text="name"
                item-value="id"
                outlined
                hide-details
                @change="search"
                dense
                label="Pabellón"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div class="col-sm-12 col-md-5 col-lg-3 d-flex justify-end">
        <v-btn
          color="primary"
          @click="search"
          class="white--text text-none elevation-0"
        >
          <v-icon class="mr-1">mdi-magnify</v-icon>
          Buscar
        </v-btn>
        <v-btn
          color="default"
          @click="clean"
          class="text-none elevation-0 ml-2"
        >
          <v-icon class="mr-1">mdi-broom</v-icon>
          Limpiar
        </v-btn>
      </div>
    </div>
    <div class="d-flex mx-1 justify-center">
      <div class="col-sm-12 col-md-5 col-lg-3 text-center">
        <div class="row justify-center">
          <div class="col-sm-12 px-1 py-1">
            <v-expansion-panels tile flat accordion>
              <v-expansion-panel class="white--text rounded-lg">
                <expositor-detail
                  v-for="(expositor, i) in expositors"
                  :expositor="expositor"
                  :key="i"
                />
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
        <div class="col-12" v-if="!expositors.length">
          <small>
            <v-icon class="mr-1">mdi-cancel</v-icon>
            No se encontraron resultados</small
          >
        </div>
      </div>
    </div>
    <div class="d-flex justify-center" v-if="lastPage > params.page">
      <div class="col-12 text-center">
        <v-btn
          class="w-100 black--text rounded-lg"
          elevation="2"
          color="secondary"
          @click="loadMore"
          >Cargar más
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import sessionMixin from "@/mixins/sessionMixin";
import moment from "moment";
import ExpositorDetail from "./components/ExpositorDetail";
import loaderMixin from "@/mixins/loaderMixin";

export default {
  name: "Expositors",
  mixins: [sessionMixin, loaderMixin],
  created() {
    moment.locale("es");
    this.params.fairId = this.fair.id;
    this.getCountries();
    this.getCompanyTypes();
    this.getPlaces();
    this.getExpositors();
  },
  components: { ExpositorDetail },
  computed: {
    fair() {
      return this.$store.getters["fairs/fair"];
    },
    internalName() {
      return (
        this.fair?.app_modules?.filter((item) => item.modules == "EXP")[0]
          ?.internalName ?? "Expositores"
      );
    },
  },
  data() {
    return {
      countries: [],
      companyTypes: [],
      expositors: [],
      lastPage: 0,
      params: {
        companyType: null,
        country: null,
        fairId: null,
        filter: null,
        page: 1,
        pageSize: 15,
        place: null,
        segment: null,
      },
      places: [],
      tab: 0,
    };
  },
  methods: {
    clean() {
      this.params.companyType = null;
      this.params.country = null;
      this.params.filter = null;
      this.params.place = null;
      this.params.segment = null;
      this.search();
    },
    getCompanyTypes() {
      this.$axiosFiseApi
        .get(`/activity-types`)
        .then((res) => {
          this.companyTypes = res.data;
        })
        .catch(() => {
          this.companyTypes = [];
        });
    },
    getCountries() {
      this.$axiosFiseApi
        .get(`/addresses/countries`)
        .then((res) => {
          this.countries = res.data;
        })
        .catch(() => {
          this.countries = [];
        });
    },
    getExpositors() {
      this.showLoader();
      this.$axiosFiseApi
        .get(`/expositors`, { params: this.params })
        .then((res) => {
          this.expositors.push(...res.data.data);
          this.lastPage = res.data.last_page;
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
    },
    getPlaces() {
      this.$axiosFiseApi
        .get(`/places/all`, { params: { fairId: this.fair.id } })
        .then((res) => {
          this.places = res.data;
        })
        .catch(() => {
          this.places = [];
        });
    },
    loadMore() {
      this.params.page++;
      this.getExpositors();
    },
    search() {
      this.params.page = 1;
      this.expositors = [];
      this.getExpositors();
    },
  },
};
</script>
<style scoped lang="scss">
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  /* display: initial; */
  visibility: hidden;
}

.v-tabs >>> .v-slide-group__prev,
.v-tabs >>> .v-slide-group__next {
  flex: none !important;
  min-width: 30px;
}

.v-tab {
  background-color: #f5f0f0;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 5px;
  color: black;
  min-width: 55px;
  max-width: 360px;
}

.v-tabs >>> .v-slide-group__content {
  padding-top: 5px;
  padding-bottom: 5px;
}

.v-tab:nth-child(2n + 1) {
  margin-left: 15px;
  margin-right: 15px;
}

.v-tabs >>> .v-tabs-slider {
  height: 0% !important;
  width: 0% !important;
}

#item-tab > span:nth-child(2) {
  color: black;
}

.v-tab--active:hover,
.v-tab--active {
  color: white !important;
  background-color: $secondary-color;
}

.v-tabs-items >>> .v-window-item--active {
  height: 100% !important;
}
</style>
