<template>
  <div>
    <v-expansion-panel class="white--text rounded-lg mb-2">
      <v-expansion-panel-header
        class="white--text elevation-6 rounded-lg"
        color="primary"
      >
        <span v-text="getHeader"></span>
        <div class="mr-auto"></div>
        <span class="text-right mx-1">VER</span>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="text--primary text-left">
        <div class="row text--primary">
          <div class="col-12 col-md-6 offset-md-3">
            <h4 class="text-center mt-4">
              {{ expositor.company.name.toUpperCase() }}
            </h4>
            <v-img
              class="mx-8 align-center"
              max-height="200"
              max-width="350"
              contain
              :src="imageBaseUrl + expositor.company.logoUrl"
            />
          </div>
          <div class="col-12 col-md-6 py-0">
            <h3>Pabellones:</h3>
            <p>{{ uniqueArray(expositor) }}</p>
          </div>
          <div class="col-12 col-md-6 py-0">
            <h3>Stands:</h3>
            <p>
              {{
                expositor.stands
                  .map(function (elem) {
                    return elem.code.toUpperCase();
                  })
                  .join(", ")
              }}
            </p>
          </div>
          <div class="col-12 col-md-6 py-0">
            <h3>Razón social:</h3>
            <p>{{ expositor.company.businessName }}</p>
          </div>
          <div class="col-12 col-md-6 py-0">
            <h3>Teléfono:</h3>
            <p>{{ expositor.company.phone }}</p>
          </div>
          <div class="col-12 col-md-6 py-0">
            <h3>Dirección:</h3>
            <p>{{ expositor.company.address }}</p>
          </div>
          <div class="col-12 col-md-6 py-0">
            <h3>Ciudad:</h3>
            <p>{{ expositor.company.city.name }}</p>
          </div>
          <div class="col-12 col-md-6 py-0">
            <h3>Departamento/Estado:</h3>
            <p>{{ expositor.company.city.department.name }}</p>
          </div>
          <div class="col-12 col-md-6 py-0">
            <h3>País:</h3>
            <p>{{ expositor.company.city.department.country.name }}</p>
          </div>
          <div class="col-12 col-md-6 py-0">
            <h3>E-mail:</h3>
            <p>{{ expositor.company.email }}</p>
          </div>
          <div class="col-12 col-md-6 py-0">
            <h3>Página web:</h3>
            <a
              :href="'http://' + expositor.company.webSite"
              target="_blank"
              class="font-bold link-color"
            >
              {{ expositor.company.webSite }}
            </a>
          </div>
          <div class="col-12 py-1">
            <h3>Reseña:</h3>
            <p>{{ expositor.company.spanishDescription }}</p>
          </div>
          <div class="col-12 py-0">
            <h3>Tipos de actividad:</h3>
            <p>
              {{
                expositor.company.activity_types
                  .map(function (elem) {
                    return elem.name;
                  })
                  .join(", ")
              }}
            </p>
          </div>
          <div class="col-12 py-0">
            <h3 class="mb-2">Categorías:</h3>
            <div
              v-for="category in expositor.company.categories"
              :key="category.id"
            >
              <p class="font-bold mx-4" v-if="category.name === 'Otro'">
                {{ category.name }}: {{ category.pivot.description }}
              </p>
              <p class="font-bold mx-4 mb-0" v-if="category.name !== 'Otro'">
                {{ category.name }}
              </p>
              <p class="ml-4">
                {{
                  category.segments
                    .map(function (elem) {
                      return elem.name;
                    })
                    .join(", ")
                }}
              </p>
            </div>
          </div>
          <div class="col-12 py-0">
            <h3>Marcas que van a exhibir:</h3>
            <p>
              {{
                expositor.company.brands
                  .map(function (elem) {
                    return elem.name;
                  })
                  .join(", ")
              }}
            </p>
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "ExpositorDetail",
  props: ["expositor"],
  data() {
    return {
      events: [],
    };
  },
  methods: {
    capitalizeFirstLetter(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    uniqueArray(item) {
      const places = item.stands.map(function (elem) {
        return elem.placeName;
      });
      const unique = [...new Set(places)];
      return unique.join(", ");
    },
  },
  computed: {
    dayText() {
      let date = moment(this.day);
      return `${this.capitalizeFirstLetter(date.format("dddd"))}, ${date.format(
        "D"
      )} de ${this.capitalizeFirstLetter(date.format("MMMM"))}`;
    },
    getHeader() {
      const header =
        this.capitalizeFirstLetter(this.expositor.company.name) +
        " (" +
        this.expositor.stands
          .map(function (elem) {
            return elem.code.toUpperCase();
          })
          .join(", ") +
        ")";
      return header.length > 25 ? header.substr(0, 25) + "..." : header;
    },
    totalEvents() {
      return this.events.length;
    },
    imageBaseUrl() {
      return process.env.VUE_APP_API_AUTH_IMAGES_URL;
    },
  },
  created() {
    moment.locale("es");
  },
};
</script>
<style scoped lang="scss">
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: auto;
}

.v-expansion-panel > .v-expansion-panel-header.border-tb {
  border: solid 1px;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  border-color: #e9e9e9;
}

.border-bottom-1 {
  border: solid 1px;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  border-color: #e9e9e9;
}

.v-expansion-panel > .v-expansion-panel-header.elevation-6 {
  border-radius: 7px;
}

.v-expansion-panel-content__wrap {
  min-width: 100% !important;
  width: 100% !important;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding-right: 0 !important;
  padding-bottom: 5px !important;
}

.v-expansion-panel.white--text >>> i {
  color: white !important;
}

.v-expansion-panel.black--text >>> i {
  color: black !important;
}

.link-color {
  color: $secondary-color;
}
</style>
